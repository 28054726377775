/*-------------------------
    Section Space
-------------------------*/
.section-padding {
   padding: 140px 0 110px;
   @media #{$smlg-device} {
      padding: 100px 0 70px;
   }
   @media #{$md-layout} {
      padding: 80px 0 50px;
   }
   @media #{$sm-layout} {
      padding: 60px 0 30px;
   }
}

.section-padding-2 {
   padding: 140px 0 100px;
   @media #{$smlg-device} {
      padding: 100px 0 60px;
   }
   @media #{$md-layout} {
      padding: 80px 0 40px;
   }
   @media #{$sm-layout} {
      padding: 60px 0 20px;
   }
}

.section-padding-equal {
   padding: 140px 0;
   @media #{$smlg-device} {
      padding: 100px 0;
   }
   @media #{$md-layout} {
      padding: 80px 0;
   }
   @media #{$sm-layout} {
      padding: 60px 0;
   }
}

// Margin

.mt--200 {
   margin-top: 200px;
}

.mt--150 {
   margin-top: 150px;
}

.mt--100 {
   margin-top: 100px;
}

.mt--50 {
   margin-top: 50px;
}

.mt--90 {
   margin-top: 90px;
}

.mt--80 {
   margin-top: 80px;
}

.mt--20 {
   margin-top: 20px !important;
}

.mb--100 {
   margin-bottom: 100px;
}

.mb--90 {
   margin-bottom: 90px;
}

.mb--80 {
   margin-bottom: 80px !important;
}

.mb--50 {
   margin-bottom: 50px !important;
}

.mb--40 {
   margin-bottom: 40px !important;
}

.mb--30 {
   margin-bottom: 30px !important;
}

.mb--20 {
   margin-bottom: 20px !important;
}

.ml--20 {
   margin-left: 20px !important;
}

// Padding
.pt--250 {
   padding-top: 250px;
}

.pt--200 {
   padding-top: 200px;
}

.pt--150 {
   padding-top: 150px;
}

.pb--90 {
   padding-bottom: 90px;
}

.pb--80 {
   padding-bottom: 80px;
}

.pb--70 {
   padding-bottom: 70px;
}

.plr--30 {
   padding: 0 30px;
}

@media #{$smlg-device} {
   .pt_lg--200 {
      padding-top: 200px;
   }
   .pt_lg--100 {
      padding-top: 100px;
   }

   .pb_lg--40 {
      padding-bottom: 40px;
   }
   .pb_lg--20 {
      padding-bottom: 20px;
   }
}

@media #{$md-layout} {
   .mb_md--70 {
      margin-bottom: 70px;
   }
   .mb_md--30 {
      margin-bottom: 30px;
   }

   .pt_md--100 {
      padding-top: 100px;
   }
   .pt_md--80 {
      padding-top: 80px;
   }

   .pb_md--70 {
      padding-bottom: 70px;
   }

   .pb_md--20 {
      padding-bottom: 20px;
   }

   .mt_md--0 {
      margin-top: 0;
   }
   .mb_md--0 {
      margin-bottom: 0;
   }
   .pb_md--0 {
      padding-bottom: 0;
   }
}

@media #{$sm-layout} {
   .pt_sm--80 {
      padding-top: 80px;
   }
   .pt_sm--60 {
      padding-top: 60px;
   }

   .pb_sm--0 {
      padding-bottom: 0;
   }
}
